<template>
  <div>
    <header class="header">
      <div class="store-img">
        <img :src="avatar ? avatar : icon_member_default" alt=""/>
      </div>
      <div class="store-name">{{ payOrderInfo.mchName }}</div>
    </header>
    <div class="plus-input">
      <div class="pay-title">
        付款金额
      </div>
      <div class="pay-box">
        <div class="S">
          <img src="../../assets/icon/S.svg" alt=""/>
        </div>
        <!-- 手写输入框 -->
        <div class="input-c">
          <div class="input-c-div-1">{{amount}}</div>
          <!-- 数字金额后边的光标 -->
          <div class="input-c-div"></div>
        </div>
      </div>

    </div>

    <!-- 备注板块 ，目前不需要添加备注，隐藏-->
    <div class="remark-k" :class="payType != 'wx' ? 'margin-top-30' : ''">
      <div class="remark">
        <div class="remark-hui" v-show="remark">{{ remark }}</div>
        <div @click="myDialogStateFn">{{ remark ? "修改" : "添加备注" }}</div>
      </div>
    </div>
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <MyDialog
        v-show="myDialogState"
        @myDialogFn="myDialogFn"
        @cancelDialog="cancelDialog"
        :remark="remark"
    >
    </MyDialog>
    <DialogOpen
        v-show="DialogOpenState"
        @cancelDialog="cancelDialog"
        :error="error"
    >
    </DialogOpen>

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <div class="keyboard-plus" v-if="isAllowModifyAmount">
      <Keyboard
          @delTheAmount="delTheAmount"
          @conceal="conceal"
          @enterTheAmount="enterTheAmount"
          @payment="pay"
          :money="amount"
          :concealSate="concealSate"
          :typeColor="typeColor[payType]"
      ></Keyboard>
    </div>

    <!-- ypay中，付款的点击事件 由 payment 修改为 pay  -->
    <!-- ypay中，付款页面是唯一的，颜色不在需要v-bind，去掉即可 -->
    <div class="bnt-pay" v-if="!isAllowModifyAmount">
      <div class="bnt-pay">
        <div
            class="bnt-pay-text"
            style="background-color:#1792e8"
            @click="pay"
        >
          付款
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
import Keyboard from "./../keyboard/keyboard";  // 手写键盘

import { getPayInfo, getPaysPackage} from '@/api/api'
import config from "@/config";
import DialogOpen from "@/views/dialog/dialogOpen.vue";

export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  // eslint-disable-next-line vue/no-unused-components
  components: {DialogOpen, Keyboard,MyDialog},
  data: function () {
    return {
      payType:'#07c160',
      remark:'',
      merchantName: 'ypay',  // 付款的商户默认
      avatar: require("../../assets/images/store.png"), // 商户头像默认
      amount: '',  // 支付金额默认
      resData: {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      concealSate:'',
      myDialogState:false,
      isAllowModifyAmount:true,
      DialogOpenState:false,
      typeColor:'',
    }
  },

  mounted() {
    this.setPayOrderInfo(); //获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo() {
      const that = this
      getPayInfo().then(res => {
        that.payOrderInfo = res
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },
    myDialogStateFn(){
      this.myDialogState=true;
    },
    myDialogFn(event){
      this.remark=event
      this.myDialogState=false;
    },
    cancelDialog(){
      this.myDialogState=false;
      this.DialogOpenState=false
    },
    enterTheAmount(event){
      /*判断总体数字是否超过10为*/
      let strs=this.amount+event;
      if(strs.length>10){
        return;
      }
      /*是否存在小数点，存在了不能再去点击*/
      if(this.amount.includes('.') && event=='.'){
        return;
      }
      /*是否超过两位小数点*/
      if(this.amount.toString().match(/^\d+(\.\d{2})+\.?$/)){
        return;
      }
      /*是否第一个选的是点号*/
      if(this.amount=='' && event=='.' || this.amount=='' && event==0){
        if(event==0){
          this.amount=0+'.';
        }else{
          this.amount=0+event;
        }
      }else{
        this.amount=this.amount+''+event
      }
    },
    delTheAmount(){
      this.amount=this.amount.slice(0, -1);
    },
    conceal(){

    },
    payment(){

    },
    // 支付事件
    pay: function () {
      // 该函数执行效果慢
      let that = this;
      if(this.amount==''){
        return;
      }
      if(this.amount<=0){
        this.error='请输入正确的金额';
        this.DialogOpenState=true;
        return;
      }
      getPaysPackage(this.amount).then(res => {

        //订单创建异常
        if (res.code != '0') {
          this.error=res.msg;
          this.DialogOpenState=true;
          return;
        }

        if (res.data.orderState != 1) { //订单不是支付中，说明订单异常
          this.error=res.data.errMsg;
          this.DialogOpenState=true;
          return;
        }

        if (!window.AlipayJSBridge) {
          document.addEventListener('AlipayJSBridgeReady', function () {
            that.doAlipay(res.data.alipayTradeNo);
          }, false);
        } else {
          that.doAlipay(res.data.alipayTradeNo);
        }

      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },


    doAlipay(alipayTradeNo){

      const that = this

      // eslint-disable-next-line no-undef
      AlipayJSBridge.call("tradePay", {
        tradeNO: alipayTradeNo
      }, function (data) {
        if ("9000" == data.resultCode) {
          // alert('支付成功！');

          // //重定向
          if(that.payOrderInfo.returnUrl){
            location.href = that.payOrderInfo.returnUrl;
          }else{
            this.error='支付成功！';
            this.DialogOpenState=true;
            window.AlipayJSBridge.call('closeWebview')
          }

          //‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
          // ‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
        }else if("8000" == data.resultCode || "6004" == data.resultCode){ //其他

          // alert(JSON.stringify(data));
          // window.AlipayJSBridge.call('closeWebview')

        }else{ ///其他异常信息， 需要取消订单
          // this.error='支付取消！';
          // this.DialogOpenState=true;
          // window.AlipayJSBridge.call('closeWebview')
        }
      });
    },

  }


}
</script>
<style lang="css" scoped>
@import './pay.css';
</style>
